import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr99.jpg";

class TeamDetails extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>İHRACAT DESTEKLERİ</span></h1>
								
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row member-detail">
								
								<div className="col-lg-12 col-md-7 mb-30">
									<h3 className="title">Markalaşma Destekleri</h3>
									<p className="mb-30">Yurtdışında reklam ve tanıtım çalışmaları yaparak marka görünürlüğünüzü artırdığınız takdirde Ticaret Bakanlığımız yaptığınız markalaşma tanıtım harcamalarınızı büyük oranda destekliyor. Reklam Destekleri kapsamında; 4 yıl süresince ve istenilen sayıda ülkede yapacağınız katalog bastırma, sosyal medya, dergi, gazete, televizyon reklamları ve pop-up mağazacılık harcamalarınız, Ticaret Bakanlığı'nın kira desteğinden faydalanan bir biriminiz yoksa tanıtım harcamalarınızın % 60'ının yıllık 250.000 ABD Dolarına kadar olan bölümü, Ticaret Bakanlığı'nın kira desteğinden faydalanan bir biriminiz varsa yine %60’lık bölümüne ilaveten, sadece destekten faydalanan birimin bulunduğu ülke için yıllık 150.000 ABD Doları tanıtım desteği alabilirsiniz. Biriminiz olmayan ülkelerin toplamı için ise yine 150.000 ABD Doları destek alabilirsiniz. Hedef ve Öncelikli Ülkede tanıtım yaparsanız ve firma merkeziniz 4.5.6. Teşvik bölgesinde ise destekleriniz belirli oranlarda artırılır. Ayrıca, Türkiye’de tescilli bir veya birden fazla markanız varsa, bu markalarınızı dünyanın herhangi bir yerinde tescil ettirmek istiyorsanız tescil işlemleriniz için yaptığınız harcamalarınız içinde büyük oranda destek alabilirsiniz. Yurt dışı marka tescili konusunda destek süresi 4 yıldır ve % 50 oranında yıllık 50.000 ABD Dolarına kadar olan bölümü desteklenir. Reklam ve Markalaşma destekleriyle ilgili daha detaylı bilgi almak için SpaceECS firma uzman yetkililerimizden bilgi alabilirsiniz..</p>
									<h3 className="title">Pazara Giriş Destekleri</h3>
									<p className="mb-30">Ülkemizde üretici veya ticari faaliyette bulunan bir şirketseniz ve ihracat yapıyor yada yapmak istiyorsanız, ihracat yapmaya başlamadan önce ve pazara girdikten sonra yaptığınız harcamaların büyük bir oranı Ticaret Bakanlığımız tarafından desteklenir. Yurtdışı Pazar Araştırma Desteği kapsamında; 1) Bu kapsamında sınai ve/veya ticari faaliyette bulunan şirketler tarafından gerçekleştirilen yurt dışı pazar araştırması gezilerine ilişkin giderler %70 oranında ve yurt dışı pazar araştırması gezisi başına en fazla 5.000 ABD Dolarına kadar desteklenir. (2) Bir yurt dışı pazar araştırması gezisi kapsamında en fazla iki şirket çalışanının aşağıda belirtilen giderleri desteklenir. a) Ulaşım: Uluslararası ve şehirlerarası ulaşımda kullanılan ekonomi sınıfı uçak, tren, gemi ve otobüs bileti ücretleri ile günlük 50 ABD Dolarına kadar araç kiralama giderleri. b) Konaklama: Kişi başına günlük 150 ABD Dolarına kadar konaklama (oda + kahvaltı) giderleri (Değişik: RG-17/1/2015-29239) (1) Her takvim yılı içerisinde şirket başına en fazla on yurt dışı pazar araştırması gezisi desteklenir. (2) Yurt dışı pazar araştırması gezisinin en az iki, yol hariç en fazla on günlük kısmı desteklenir. (3) Yurt dışı pazar araştırması gezisi süresince, yolculuk ve gidilen ülkenin resmi tatil günleri hariç olmak üzere, her gün için araştırma yapılan ülkede yerleşik en az bir kurum, kuruluş veya şirketle görüşme yapılmalıdır. Görüşme yapılmayan günler için ulaşım ve konaklama giderleri desteklenmez.</p>
									<h3 className="title">Yurtdışı Rakip Analizi</h3>
									<p className="mb-30">Hedef Pazarlara girmeden önce en verimli eylem planını hazırlamak rakipleri doğru analiz etmekten geçer. Rakip Analizi markanızın pazarlanmasının en önemli ve kritik parçalarından biridir. Space ECS hedef pazarın aktif oyuncularından olmanız için en etkili adımları en sağlam ve hızlı biçimde atmanızı sağlayarak yanınızda olacaktır.</p>
									<h3 className="title">Sağlık Turizmi Yurtdışı Pazarlama Danışmanlığı</h3>
									<p className="mb-30">Dünya sağlık turizmi son yılların en hızlı gelişen sektörlerinden biridir. Sağlık turizmi olarak seyahat edenlerin sayısı 10 Milyonu aşmış ve yıllık 100 Milyar USD lik bir ciroya ulaşmıştır. Sağlık Turizmi eirmedical markamız 2008 yılında Bükreş, Romanya merkezli olarak Ülkemizde olan uluslararası yetkinlikteki hastanelerimizin belirli ülkelerde reklam ve tanıtım kampanyalarını yaparak işbirlik ağlarını güçlendirmek amacıyla kurulmuştur. Ağırlıklı olarak Romanya , Almanya, İngiltere, İsrail ve ABD'de Pazar çalışmalarımızı yapıyor ve belirli hastalıklarda müşterilerimize birinci sınıf bakım sağlayan tedavi seçeneklerini ve süreçlerini organize ediyoruz.. Tüm hasta tıbbi kayıtlarını gözden geçirip tanıyı tamamlayarak , tedaviyi onaylamak ve ikinci bir tıbbi görüş için ücretsiz olarak telekomünikasyon hizmeti sunuyoruz.</p>
									<h3 className="title">Yurtdışı Pazar Araştırması</h3>
									<p className="mb-30">Hedef ülke pazarlarına girmeden önce kapsamlı bir şekilde yapılan Pazar Analizleri işletmenizde pazarlama, satış, fiyatlandırma da alacağınız yanlış kararların önüne geçer. Space ECS olarak yılların bize verdiği tecrübemizle hedef pazarlarınızı en inovatif araştırma yöntemlerini kullanarak raporluyor. Stratejik yol haritanızı hazırlamanızda çözüm ortağınız oluyoruz.</p>
									<h3 className="title">Stratejik Yönetim ve Planlama</h3>
									<p className="mb-30">İhracat hedeflerimizin, Neresindeyiz ve Neresin de olmak istiyoruz? Olmak istediğimiz yere en hızlı, en inovatif ve en doğru şekilde nasıl ulaşırız.. Space ECS Group durum analizinden başlayarak gerekli stratejik istihbarat çalışmaları ile size en uygun yöntemlerle sonuçları planlar. Hedefinize en uygun yapıyı oluşturarak en hızlı ve doğru yöntemlerle olmak istediğiniz yerde olmanızda en büyük çözüm ortağınız olur.</p>

								</div>
							</div>
						</div>
					</section>
				
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default TeamDetails;