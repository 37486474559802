import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import logo2 from '../../images/logo2.png';
import icon1 from '../../images/icon/contact/icon1.png';
import icon2 from '../../images/icon/contact/icon2.png';
import icon3 from '../../images/icon/contact/icon3.png';
import recentBlogPic1 from '../../images/blog/recent-blog/pic1.jpg';
import recentBlogPic2 from '../../images/blog/recent-blog/pic2.jpg';

class aboutSection extends Component{
	render(){
		return(
			<>
				
				<footer className="footer">
					<div className="footer-info bg-primary">
						<div className="container">
							<div className="row align-items-center">
								
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell"><img src={icon3} alt=""/></span> 
										</div>
										<div className="icon-content">
											<p>TANTAVİ MAH. ESTERGON CAD. EXEN İSTANBUL SİTESİ F
BLOK NO: 24F İÇ KAPI NO: 130 ÜMRANİYE / İSTANBUL-TÜRKİYE</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell"><img src={icon1} alt=""/></span> 
										</div>
										<div className="icon-content">
											<p>Tel: 0850 550 84 77 <br/>Fax: 0216 465 15 00 <br/> Wp: +90 552 358 84 77</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell"><img src={icon2} alt=""/></span> 
										</div>
										<div className="icon-content">
											<p>b2b@spaceecs.com</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-top bt0">
						<div className="container">
							<div className="row">
								<div className="col-xl-3 col-md-6">
									<div className="widget footer_widget widget-link-2">
										<h5 className="footer-title">Referanslar</h5>
										<ul>
											<li>Acıbadem Maslak Hastanesi </li>
											<li>Amerikan Hastanesi</li>
											<li>Medipol Üniversitesi Hastanesi </li>
											<li>Livhospital </li>
											<li>Globalmedilaç san. Tic.</li>
											<li>Oreonpro</li>
											<li> Eclipse Plastik san. Tic.</li>
											<li>Auchan </li>
											<li>Remmyhome </li>
											<li>Turmak </li>
											<li>Posuda.ru </li>
											<li>Bingo</li>
											<li>Spar</li>
											<li>Delhaize Serbia </li>
											<li>Bager</li>
											<li>Qlux</li>
											<li>Birpa</li>
											<li>Fianit Express</li>
											<li>Selka</li>
											<li>Titiz</li>
										</ul>
										<div className="ft-content">
											<i className="fa fa-phone"></i>
											<span>BIZE ULAŞIN</span>
											<h4>0850 550 8477 </h4>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-md-6">
									<div className="widget widget-link-2">
										<h5 className="footer-title">HIZMETLER</h5>
										<ul>
											<li><Link to="/about-us">YURTDIŞI REKLAM VE MARKALAŞMA</Link></li>
											<li><Link to="#">IHRACAT TEŞVİK DANIŞMANLIĞI</Link></li>
											<li><Link to="/booking">YURTDIŞI RAKIP ANALİZİ</Link></li>
											<li><Link to="#">SAĞLIK TURİZMİ YURTDIŞI PAZARLAMA DANIŞMANLIĞI</Link></li>
											<li><Link to="/services">YURT DIŞI PAZAR ARAŞTIRMA</Link></li>
											<li><Link to="#">STRATEJIK YÖNETIM VE PLANLAMA</Link></li>
											<li><Link to="/pricing-plan"></Link></li>
										</ul>							
									</div>
								</div>
								<div className="col-xl-3 col-md-6">
									
								</div>
								<div className="col-xl-3 col-md-6">
									<div className="widget widget_info">
										<h5 className="footer-title">SOSYAL MEDIA</h5>
										
										<ul className="list-inline ft-social-bx">
											<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/SpaceecsGroup-101303031644615" className="btn button-sm"><i className="fa fa-facebook"></i></a></li>
											
											<li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/spaceecs-group" className="btn button-sm"><i className="fa fa-linkedin"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/spaceecs/" className="btn button-sm"><i className="fa fa-instagram"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-start">
									<p className="mb-0">© Copyright Serfix 2021. All right reserved.</p>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-end">
									<ul className="widget-link">
										<li><Link to="/index">Home</Link></li>
										<li><Link to="/about-us">About</Link></li>
										<li><Link to="/faq-1">FAQs</Link></li>
										<li><Link to="/services">Services</Link></li>
										<li><Link to="/contact-us">Contact</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>
			
			</>
		);
	}
}

export default aboutSection;