import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import iconPDF from "../../../images/icon/pdf.png";
import iconDOC from "../../../images/icon/doc.png";

class widgetBrochure extends Component {
	render() {
		return (
			<>
				
				
				<div className="widget">
					<div className="brochure-bx">
						<h5 className="title-head">İndirin</h5>
						<div class="columns download">
							<p>
								<a href="/SpaceECS-HİZMETLERİMİZ.pdf" download>SpaceEcs Hizmet Listesi</a>

							</p>
						</div>

						<div class="columns download">
							<p>
								<a href="/SpaceEcs.pdf" download>SpaceEcs</a>

							</p>
						</div>

					</div>
				</div>
			</>
		);
	}
}

export default widgetBrochure;