import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PricingTableSection extends Component {
	render() {
		return (
			<>
				<section className="section-area bg-white section-sp1">
					<div className="container">
						<div className="heading-bx text-center">

							<h2 className="title mb-0">ULUSLARARASI İŞ GELİŞTİRME PAKET VE HİZMETLERİMİZ</h2>

						</div>
						<div className="pricingtable-row">
							<div className="row">
								<div className="col mb-30">
									<div className="pricingtable-wrapper style1">
										<div className="pricingtable-inner">
											<div className="pricingtable-main">
												<div className="pricingtable-title">
													<h4>SpaceECS NOKTA ATIŞ İHRACAT PAKETİ</h4>
												</div>
											</div>
											<ul className="pricingtable-features">
												<li><i className="fa fa-check"></i> Mevcut Durum Analizi</li>
												<li><i className="fa fa-check"></i> Strateji ve Yol Haritasının Belirlenmesi</li>
												<li><i className="fa fa-check"></i> Dijital Projeksyon</li>
												<li><i className="fa fa-check"></i> Hedef Pazar ve Ülkelerin Belirlenmesi</li>
												<li><i className="fa fa-check"></i> Potansiyel Müşteri Analizi</li>
												<li><i className="fa fa-check"></i> Rakip Analizi</li>
												<li><i className="fa fa-check"></i> Fiyat Analizi</li>
												<li><i className="fa fa-check"></i> Firmaya Özel Ürün ve Hedef Pazar Araştırma Raporu</li>
												<li><i className="fa fa-check"></i> E-mail Marketing Sistem Kurulumu</li>
												<li><i className="fa fa-check"></i> Teşvik projeksyon</li>
												<li><i className="fa fa-check"></i> B2B Marketing</li>
											</ul>
											<div className="pricingtable-footer">
												<Link to="/booking" className="btn btn-primary">Bize Ulaşın</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col mb-30">
									<div className="pricingtable-wrapper style1 pricingtable-highlight">
										<div className="pricingtable-inner">
											<div className="pricingtable-main">
												<div className="pricingtable-title">
													<h4>İHRACATA YÖNELİK DESTEK/HİBE/TEŞVİK HİZMET LİSTEMİZ</h4>
												</div>
											</div>
											<ul className="pricingtable-features">
												<p><i className="la la-clock"></i> SÜREÇ</p>
												<li><i className="fa fa-check"></i> Uygun Destek Seçimi</li>
												<li><i className="fa fa-check"></i> Proje Hazırlanması</li>
												<li><i className="fa fa-check"></i> Proje Başvurusu</li>
												<li><i className="fa fa-check"></i> Süreç Takibi</li>
												<li><i className="fa fa-check"></i> Proje Yönetimi</li>
												<li><i className="fa fa-check"></i> Raporlama</li>
												<p><i className="la la-clock"></i>  TİCARET BAKANLIĞI TEŞVİK VE DESTEKLER</p>
												<li><i className="fa fa-check"></i> Reklam Tanıtım Desteği</li>
												<li><i className="fa fa-check"></i> Yurt Dışı Pazar araştırma Desteği</li>
												<li><i className="fa fa-check"></i> Pazara Giriş Belgeleri Desteği</li>
												<li><i className="fa fa-check"></i> Fuar Desteği</li>
												<li><i className="fa fa-check"></i> Yurt Dışı Birim Kira Desteği</li>
												<li><i className="fa fa-check"></i> Yurt Dışı Marka Tescil desteği</li>
												<li><i className="fa fa-check"></i> Yurt Dışında Yerleşik Şirket Alımı-Danışmanlık Desteği</li>
												<li><i className="fa fa-check"></i> E-Ticaret Sitelerine Üyelik Desteği</li>
												<p><i className="la la-clock"></i>  KOSGEB</p>
												<li><i className="fa fa-check"></i> Yurt Dışı Pazar Destek Programı</li>

											</ul>
											<div className="pricingtable-footer">
												<Link to="/booking" className="btn btn-primary">Bize Ulaşın</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col mb-30">
									<div className="pricingtable-wrapper style1">
										<div className="pricingtable-inner">
											<div className="pricingtable-main">
												<div className="pricingtable-title">
													<h4>B2B MARKETING</h4>

												</div>
											</div>
											<ul className="pricingtable-features">
												<p><i className="la la-clock"></i> Hızlı ve Kolay İşleyen Bir Süreç İçin İhracatta Dijital Dönüşüm</p>
												<li><i className="fa fa-check"></i> Araştırma ve Analiz</li>
												<li><i className="fa fa-check"></i> Dijital Pazarlama</li>
												<li><i className="fa fa-check"></i> Web Sitesi</li>
												<li><i className="fa fa-check"></i> Google Market Finder</li>
												<li><i className="fa fa-check"></i> Data Planlama</li>
												<li><i className="fa fa-check"></i> Seo</li>
												<li><i className="fa fa-check"></i> Sosyal Medya</li>
												<li><i className="fa fa-check"></i> Creative</li>
												<li><i className="fa fa-check"></i> Content</li>
												<li><i className="fa fa-check"></i> Ölçümleme</li>
											</ul>
											<div className="pricingtable-footer">
												<Link to="/booking" className="btn btn-primary">Bize Ulaşın</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div  className="row">
								<div className="col mb-30">
									<div className="pricingtable-wrapper style1">
										<div className="pricingtable-inner">
											<div className="pricingtable-main">

												<div className="pricingtable-title">
													<h4>Madeintur.Com</h4>

												</div>
											</div>
											<ul className="pricingtable-features">
												<li><i className="fa fa-check"></i> Mağaza Kurulumu</li>
												<li><i className="fa fa-check"></i> Katagori Belirlenmesi</li>
												<li><i className="fa fa-check"></i> Ürün Yerleştirilmesi</li>
												<li><i className="fa fa-check"></i> Ticari İstihbarat ve Hedef Pazar</li>
												<li><i className="fa fa-check"></i> Sektör Analiz</li>
												<li><i className="fa fa-check"></i> Rakip Analiz</li>
												<li><i className="fa fa-check"></i> Dijital Reklam</li>
												<li><i className="fa fa-check"></i> Seo</li>
												<li><i className="fa fa-check"></i> Mailing</li>
											</ul>
											<div className="pricingtable-footer">
												<Link to="/booking" className="btn btn-primary">Bize Ulaşın</Link>
											</div>
										</div>
									</div>
								</div>

								<div className="col mb-30">
									<div className="pricingtable-wrapper style1">
										<div className="pricingtable-inner">
											<div className="pricingtable-main">

												<div className="pricingtable-title">
													<h4>SAĞLIK TURİZMİ TEŞVİK DANIŞMANLIĞI</h4>

												</div>
											</div>
											<ul className="pricingtable-features">
												<li><i className="fa fa-check"></i> Sağlık Kuruluşunun Kimlik Analizinin yapılması</li>
												<li><i className="fa fa-check"></i> Acente – Sağlık Kuruluşu – İş birliği Kurulışu statü belirleme</li>
												<li><i className="fa fa-check"></i> NACE kodu ve destek sistematiğinin kurulması</li>
												<li><i className="fa fa-check"></i> Teşvik Bilgilendirme Eğitimi</li>
												<li><i className="fa fa-check"></i> Teşvik Evreni Ekranı oluşturma</li>
												<li><i className="fa fa-check"></i> Teşvik Başvuru süreç bilgilendirme</li>
												<li><i className="fa fa-check"></i> Kep ve fiziki şartların düzenlenmesi</li>
												<li><i className="fa fa-check"></i> Sağlık kuruluşu ve sağlık turizmi acentelerinin devlet desteklerinin araştırılması, başvurusu, takibinin yapılması.</li>
												<li><i className="fa fa-check"></i> Sağlık Kuruluşunun, Hizmet İhracatçılar Birliğine kayıt, Ticaret Bakanlığı Akreditasyonuiçingereklievrakların hazırlanması, Teşvik dosyalarının hazırlanması,</li>
												<li><i className="fa fa-check"></i> Dosyaların hazırlanması sonrası Teşvik başvurusu yapılması, dosya takibinin ve tahsilat süreçlerinin takip edilerek sonuçlandırılması.</li>
												<li><i className="fa fa-check"></i> Sistem kurulumundan uluslararası yetkinlik belgesinin alınmasına kadar Sağlık kuruluşu için belgelendirme ve akreditasyon süreçlerinin takibinin yapılması.</li>
												<li><i className="fa fa-check"></i> Halihazırda kurulu bulunan Sağlık kuruluşunun akreditasyon süreçlerinde destek ve danışmanlık etmek,</li>
												<li><i className="fa fa-check"></i> KOSGEB, Ticaret Bakanlığı, İŞKUR desteklerinin takibi ve tahsilatının sağlanması.</li>
												<li><i className="fa fa-check"></i> Devlet desteklerinin yönetilmesi ve yapılan işlerle ilgili değerlendirme raporlarıyla gerekli yönlendirmenin yapılması, </li>
												<li><i className="fa fa-check"></i> Madde 3’ de belirtilen hizmetler ve iş süreçleri, sözleşme süresince  birlikte yapılacaktır.</li>
												
											</ul>
											<div className="pricingtable-footer">
												<Link to="/booking" className="btn btn-primary">Bize Ulaşın</Link>
											</div>
										</div>
									</div>
								</div>

								<div className="col mb-30">
									<div className="pricingtable-wrapper style1">
										<div className="pricingtable-inner">
											<div className="pricingtable-main">

												<div className="pricingtable-title">
													<h4>SAĞLIK TURİZMİ YÖNETİM DANIŞMANLIĞI</h4>

												</div>
											</div>
											<ul className="pricingtable-features">
											<li><i className="fa fa-check"></i> Sağlık Kuruluşunun Kimlik Analizinin yapılması</li>
												<li><i className="fa fa-check"></i> Web sitesi ve sosyal Medya hesaplarının incelenmesi, raporlanması ve tavsiyelerin yazılı olarak iletilmesi.</li>
												<li><i className="fa fa-check"></i> Ortak fiyat çalışmalarının yapılması</li>
												<li><i className="fa fa-check"></i> Ağırlıklı çalışılacak branşların belirlenmesi</li>
												<li><i className="fa fa-check"></i> *Tanıtım materyallerinin hazırlanması (Mailing, tanıtım yazıları) içerik konusunda strateji belirlemesi yapılır. Tavsiye ve örnekler ekinde öneriler sunulur. Hazırlama SağlıkKuruluşuna aittir.</li>
												<li><i className="fa fa-check"></i> Sosyal medya içeriklerinin planlaması ile ilgili eğitim, öneri ve post içeriği konusunda bilgilendirilme yapılması, </li>
												<li><i className="fa fa-check"></i> Bu işlerle ilgiliajans ya da çalışılacak uzmanların sağlık turizm oryantasyonlarının yapılarak eğitim desteği verilmesi, içeriğin sektöre uygun hale getirilmesi.</li>
												<li><i className="fa fa-check"></i> Uluslararası veri tabanlarına kayıt için listelerin verilmesi, işin öğretilmesi, örnek kayıtların yapılması. </li>
												<li><i className="fa fa-check"></i> SAĞLIK TURİZM ŞİRKETİ ’nin 3. parti işbirlikleri için acente, hastane, alt acente sözleşmeleri konusunda taslak oluşturulması, (hukuki sorumluluk hastaneye aittir).</li>
												
												<li><i className="fa fa-check"></i> Kurumsal kimliğinin ışığında Sağlık Kuruluşunun kullanacağı reklam materyallerinin tasarımlarının sağlık turizmi ve hedef pazara uygun hazırlanması için tavsiyelerde bulunacak, öneriler getirecektir.</li>
												<li><i className="fa fa-check"></i> Sağlık Kuruluşunun veri tabanı oluşturması, bu veri tabanının mailing olarak kullanımı ve toplu mail gönderme konusunda bilgilendirilmesi ve stratejiye katkıda bulunacaktır.</li>
												<li><i className="fa fa-check"></i> Yurtdışında faaliyette olan mevcut sağlık acenteleri, dernekler ve B2B, B2C B2G, B2M platformları ile ilgili bilgi, adres ve çalışma sistemlerinin öğretilmesi, örnek olay için mail, telefon görüşmesi, yazışma ve sözleşmelerin oluşmasını sağlamak. </li>
												<li><i className="fa fa-check"></i> *Sağlık Kuruluşunun iş geliştirme faaliyetleri için 3. Parti işbirlikleri ve olası acente yapılanması için sistemin kurulması, kurulacak sistemin ofis içi ve hizmet sağlayıcılarla yapılanmasını sağlamak amacıyla gerekli eğitim ve desteğin sağlanması.</li>
												<li><i className="fa fa-check"></i> Yurtiçi ve yurt dışında faaliyette olan mevcut sağlık acenteleri, turizm acenteleri ile işbirliği sağlanması amacıyla irtibat sağlanması ve yönlendirmelerin yapılması.</li>
												<li><i className="fa fa-check"></i> Sağlık Kuruluşu için iş geliştirme süreçlerinin oluşturulması, personele gerekli eğitimin verilmesi ve sonraki süreçlerin takip edilmesi.</li>
												<li><i className="fa fa-check"></i> *Hastabulunmasına yönelik reklam aşamasından başlayarak operasyonel süreçlerin programlanması, Sağlık Kuruluşunun bu konuda gerekli hazırlıkları yapması için tedarikçi ve hizmet standartlarının belirlenmesi.</li>
												<li><i className="fa fa-check"></i> Sağlık Kuruluşunun, pazarlama ağını geliştirmek için proje başlıkları ve yol haritalarının hazırlanması, uygulama için gerekli bilgi ve belge desteğini sağlanmak.</li>
												<li><i className="fa fa-check"></i> STK ve devlet kuruluşları ile iş geliştirmek için bilgi, belge ve enformasyon sağlamak</li>
												
											</ul>
											<div className="pricingtable-footer">
												<Link to="/booking" className="btn btn-primary">Bize Ulaşın</Link>
											</div>
										</div>
									</div>
								</div>




							</div>
						</div>
					</div>
				</section>

			</>
		);
	}
}

export default PricingTableSection;