import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import WidgetBrochure from "../elements/widget/widget-brochure";
import WidgetHelp from "../elements/widget/widget-help";

// Images
import bnrImg from "../../images/banner/bnr5.jpg";
import Pic1 from "../../images/service-section/pic1.jpg";

class ServiceEngineDiagnostics extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Sağlık Turizmi Yurtdışı Pazarlama Danışmanlığı</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									
								</nav>
							</div>
						</div>
					</div>
					
					<div className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-5">
									<aside className="sticky-top pb-1">
										
										<div className="widget">
											<ul className="service-menu">
												<li className="active"><Link to="/service-engine-diagnostics"><span>Yurtdışı Reklam ve Markalaşma</span><i className="fa fa-angle-right"></i> </Link></li>
												<li><Link to="/service-lube-oil-and-filters"><span>Uluslararası İş Geliştirme ve İhracat Danışmanlıği</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-belts-and-hoses"><span>Yurtdışı Rakıp Analızı</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-air-conditioning"><span>Sağlık Turizmi Yurtdışı Pazarlama Danışmanlığı</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-brake-repair"><span>Yurt Dışı Pazar Araştırması</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-tire-and-wheel-services"><span>Stratejik Yönetim ve Planlama</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										
										
										
										<WidgetBrochure />
										
									</aside>
								</div>
								<div className="col-lg-8 col-md-7">
									<div className="ttr-media mb-30">
										<img src={Pic1} className="imghight" alt=""/>
									</div>
									<div className="mb-30">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Sağlık Turizmi Yurtdışı Pazarlama Danışmanlığı</h4>
											<p className="mb-0">Dünya sağlık turizmi son yılların en hızlı gelişen sektörlerinden biridir. Sağlık turizmi olarak seyahat edenlerin sayısı 10 Milyonu aşmış ve yıllık 100 Milyar USD lik bir ciroya ulaşmıştır. Sağlık Turizmi eirmedical markamız 2008 yılında Bükreş, Romanya merkezli olarak Ülkemizde olan uluslararası yetkinlikteki hastanelerimizin belirli ülkelerde reklam ve tanıtım kampanyalarını yaparak işbirlik ağlarını güçlendirmek amacıyla kurulmuştur. Ağırlıklı olarak Romanya , Almanya, İngiltere, İsrail ve ABD'de Pazar çalışmalarımızı yapıyor ve belirli hastalıklarda müşterilerimize birinci sınıf bakım sağlayan tedavi seçeneklerini ve süreçlerini organize ediyoruz.. Tüm hasta tıbbi kayıtlarını gözden geçirip tanıyı tamamlayarak , tedaviyi onaylamak ve ikinci bir tıbbi görüş için ücretsiz olarak telekomünikasyon hizmeti sunuyoruz.</p>
										</div>
										
									</div>
									
								</div>
							</div>
						</div>
					</div>
				
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default ServiceEngineDiagnostics;