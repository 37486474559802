import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import LatestBlogSection from "../elements/latest-blog-slider";


// Images
import bnrImg from "../../images/banner/bnr4.jpg";
import testPic1 from "../../images/testimonials/pic1.jpg";

import defaultPic2 from "../../images/blog/default/pic2.jpg";
import defaultPic3 from "../../images/blog/default/pic3.jpg";
import defaultPic5 from "../../images/blog/default/pic5.jpg";
import defaultPic6 from "../../images/blog/default/pic6.jpg";


class BlogDetails extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bnrImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Blog Details</span></h1>
							</div>
						</div>
					</div>
					<LatestBlogSection />
					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="container">

									<div className="blog-lg blog-single">
										<div className="action-box blog-lg">
											<img src={defaultPic2} alt="" />
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><img src={testPic1} alt="" />By <Link to="#">Irem</Link></li>
												<li className="date"><Link to="#">17 Ocak 2022</Link></li>
											</ul>
											<div className="ttr-post-title">
												<h3 className="post-title">B2B Nedir? B2B Marketing Nasıl Olmalı?</h3>
											</div>
											<div className="ttr-post-text">
												<p>B2B nedir denildiğinde, açılım olarak Business toBusiness’ı ifade etse de bu pazarlamanın merkezinde yer alan kritik konu müşteri yönetimidir.
													<ol>
														<li>
															Mesele satış yapmaktan müzakere yönetimine doğru yaklaştıkça, müşteri yönetiminin anlam ve öneminin arttığını söyleyebiliriz. Uzun vadeli-değerli müşteri ilişkileri geliştirmek için sahip olunması gereken bilgilerin peşinden koşmaktansa, eski bildiklerimiz ve sezgilerimiz üzerine kurduğumuz “satıcılık faaliyetleri”, ilişkilerin derinleşmesini engelleyen bir faktör olarak karşımıza çıkıyor.
														</li>
														<li>
															İstanbul Gelişim Üniversitesi Öğretim Üyesi ve ETKİ Yönetim Etkinliği Geliştirme Merkezi ortağı Dr. Kutalmış Emre Ceylan’ın dayanaksız müşteri yaklaşımıyla ilgili hazırladığı makalede; B2B marketing en önemli konusu olan müşteri yönetiminde kararların herhangi bir bilgiye dayanmadan; sezgisel olarak verildiği her durumun firmanın zararla çıkacağı belirtiliyor. Bu nedenle, hangi tip müşterinin hangi ürün grupları ile ilgilendiğini çok iyi tahlil etmek ve ürün grubu hedefleri ile müşteri hedeflerinin uyumunu izlemek, yapılması gereken temel müşteri yönetimi uygulamaları arasında yerini alıyor.
														</li>



													</ol>
												</p>

											</div>
											<div className="ttr-post-footer">

												<div className="share-post ms-auto">
													<ul className="social-media">
														<li><strong>SHARE:</strong></li>
														<li><a target="_blank" href="https://www.facebook.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
														<li><a target="_blank" href="https://www.google.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-google-plus"></i></a></li>
														<li><a target="_blank" href="https://www.linkedin.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
														<li><a target="_blank" href="https://twitter.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>

									</div>
									<div className="blog-lg blog-single">
										<div className="action-box blog-lg">
											<img src={defaultPic3} alt="" />
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><img src={testPic1} alt="" />By <Link to="#">Irem</Link></li>
												<li className="date"><Link to="#">17 Ocak 2022</Link></li>
											</ul>
											<div className="ttr-post-title">
												<h3 className="post-title">Akıllı Müşteri Temelini Oluşturan Uygulamalar Neler?</h3>
											</div>
											<div className="ttr-post-text">
												<p>Kapatılma aşamasına gelmiş Türkiye’deki bir kargo şirketinin müşteri analizleri neticesinde değiştirdiği müşteri politikaları, müşteri yönetiminin bir şirkette neleri değiştirebildiğine iyi bir örnektir:
													Şubeden gönderim yapan tekil müşterilerin yanı sıra 45 bin cari ile çalışan şirketin müşteri politikaları, 10 yıldır yoğun rekabet içinde bulunduğu rakiplerinin etkisi ile can yakıcı bir hal almıştı. Liste fiyatlarında problem yoktu. Problem, cari çalışıldığı için ekstra iskonto uygulanmış müşteriler ve bu müşterilerin gönderim tercihlerindeydi. 45 bin müşterinin yıllar bazında yaptıkları cirolar, karlılık oranları (yeni geliştirilen karlılık tahmini modeli müşterilerin %90’ı için; %95 oranında doğru rakamı verdi), nakit ve çekli ödemelerin ortalama süreleri, hangi mesafelere, hangi büyüklükteki ürünlerden ne kadar miktarda gönderdikleri gibi konular büyük bir veri tabanında birleştirildi ve sonra analizlere başlandı. Müşteri sınıflandırmaları neticesinde her müşteri sınıfına yönelik (sülük, kelebek, gerçek dost ve yabancı) yaklaşımlar geliştirildi ve bunlar müşteri müşteri uygulanmaya başlandı.

													Neticede şirket, yapılan yeniden fiyatlandırma ve hizmet biçimlerindeki değişim neticesinde planlı olarak müşterilerinin %25’ini ve cirosunun %15’ini terk etti. Küçültme (downsizing) çalışmalarının da temelini oluşturan bu analizler sonucunda şirket %10 net zararını, 1 sene içinde %7 kara dönüştürmeyi başardı.
													İşte bu örnekteki gibi; müşteri ile meşgul ediciyi birbirinden ayrıştırıcı, hak eden müşteriye hak ettiği kadar kaynak kullandıran analizler, bugünkü akıllı müşteri yönetiminin temelini oluşturan uygulamalar oluyor.
													<ul>
														<li>
															Müşteri hedefleri ile uzun vadeli müşteri politikalarını eşleştirebilmek,
														</li>
														<li>
															Müşterinin bir müşteri olmaktan çıkıp sağlıklı ilişkiler yürütülen bir partner haline getirilmesi,
														</li>
														<li>
															Müşterinin kalp atışlarını-nabzını doğru analizlerle takip edebilmek,
														</li>
														<li>
															Müşteri için belirlediğiniz eylem planlarını doğru şekilde uygulayabilecek yetkinlikte saha yöneticileri ile beraber bu işi planlamak,
														</li>
														<li>
															Yaptığınız ziyaret sıklığından onlar için kullandığınız her bütçeye kadar mantıklı bir bütünün analitik parçaları olarak bu ilişkiyi kurgulamak,
														</li>
													</ul>
													Bugünkü müşteri yönetiminin öncelikli uygulamalarında yer alıyor.
												</p>

											</div>

											<div className="ttr-post-footer">

												<div className="share-post ms-auto">
													<ul className="social-media">
														<li><strong>SHARE:</strong></li>
														<li><a target="_blank" href="https://www.facebook.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
														<li><a target="_blank" href="https://www.google.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-google-plus"></i></a></li>
														<li><a target="_blank" href="https://www.linkedin.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
														<li><a target="_blank" href="https://twitter.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>

									</div>
									

								
								






									<div className="blog-lg blog-single">
										<div className="action-box blog-lg">
											<img src={defaultPic5} alt="" />
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><img src={testPic1} alt="" />By <Link to="#">Irem</Link></li>
												<li className="date"><Link to="#">17 Ocak 2022</Link></li>
											</ul>
											<div className="ttr-post-title">
												<h3 className="post-title">Yanı Başımızdaki Dev Pazar Avrupa Birliği</h3>
											</div>
											<div className="ttr-post-text">
												<p>
													27 ülkeden oluşan Avrupa Birliği (AB), dünya üzerindeki en büyük siyasi ve ekonomik örgütlenmedir. Dünya nüfusunun yaklaşık %6’sını teşkil etmesine rağmen, AB dünyanın en büyük ekonomileri ve ticaret aktörleri arasında yer almaktadır. Buna ilaveten büyük ve çeşitlendirilmiş pazar yapısı, sahip olduğu gelişmiş alt yapı imkânları, tüm üye ülkelerde aynı şekilde uygulanan teknik standartlar ve sağlık/bitki sağlığı önlemleri, mevcut pazar büyüklüğü ve gelecekte de sahip olacağı ticaret potansiyeli ile AB ülkemiz açısından çok önemli bir pazardır.
													<br />
													AB, yaklaşık 448 milyonluk tüketici kapasitesi ve 33.928 dolarlık kişi başı geliri ile dünyanın en büyük ekonomileri arasında olmayı sürdürmektedir. AB 15,1 trilyon dolarlık Gayri Safi Yurtiçi Hasılası (GSYH) ile  (ABD’nin ardından) dünyanın en büyük ikinci ekonomisidir.
													<br />
													AB tek bir blok olarak düşünüldüğünde dünya mal ihracatından %15,2, dünya ithalatından ise %14,7 oranında pay alarak, dünya mal ihracatında ve ithalatında ikinci sırada yer almaktadır. Hizmet ticaretinde de 112 milyar dolar ticaret fazlasıyla AB, dünyanın en büyük ihracat ve ithalatçısı konumundadır.
													<br />

													Son derece derin ve çeşitlendirilmiş bir pazar yapısına sahip olan AB, yatırım malları, kimyasal ürünler ve muhtelif tüketim malları imalatı ve ithalatının yanı sıra tarım ürünleri, ham madde ve enerji gibi ana ürünleri de ithalat ederek değer zincirinin her aşamasında ihracatçılarımıza sayısız fırsatlar sunmaktadır. Yaklaşık 2,1 trilyon dolarlık bir ithalat hacmine sahip olan AB birçok sektördeki ithalat hacmini günden güne artırmaktadır. Bu ithalat büyüklüğü ile söz konusu sektörler ihracatçılarımıza büyük bir pazar fırsatı sunmaktadır.
													<br />
													Şeffaf piyasa yapısı, daha az ticaret engeli ve daha açık mevzuat yapısı ile büyük bir tüketici kesimine sahip AB, benzersiz ulaşım ve taşımacılık alt yapısı ile ve aynı zamanda yüksek yolcu taşıma kapasiteli havalimanları ile ürün ve hizmetleri güvenli bir şekilde ulaştırma imkânı sağlamaktadır.
													<br />
													Ülkemiz ile AB Arasındaki Mevcut Ticaret
													<br />

													31 Aralık 1995 tarihinde Gümrük Birliği’nin ihdas edilmesi ile birlikte Türkiye ve AB arasında büyük bir ivme kazanan ticaret hacmi 2020 yılında 143 milyar dolar olarak gerçekleşmiş ve AB ülkemizin en önemli ticaret ortağı olmayı sürdürmüştür. Ülkemiz, AB’nin toplam ihracatından aldığı %3,4 pay ile 6. sırada yerini almıştır.
													<br />
													AB, 2020 yılında 69 milyar dolar ile ihracatımızdan %41,3 oranında pay almakta olup toplam ihracatımızda ilk sırada yer almaktadır.
													<br />
													Ülkemiz AB’nin toplam ithalatında ise %3,7’lik payla 6. sırada gelmektedir (AB ülkelerinin kendi aralarında yaptığı ticaret hariç tutulduğunda). Ayrıca AB, ülkemizin ihracatında olduğu gibi ithalatında da ilk sırada yer almaktadır. 2020 yılı rakamlarına göre; Türkiye 219 milyar dolarlık toplam mal ithalatının 73 milyar dolarlık kısmını (%33,4’lük pay) AB’den gerçekleştirmiştir. 2020 yılında ülkemizin AB ile olan ticaretinde ihracatın ithalatı karşılama oranı %95,4 seviyesinde gerçekleşmiştir.
													<br />
													Hâlihazırda yalnız sanayi ve işlenmiş tarım ürünlerini kapsayan Gümrük Birliğinin önümüzdeki dönemde kapsamı genişletilerek ve derinleştirilerek güncellenmesi durumunda yanı başımızdaki dev pazar konumunda olan AB’nin öneminin daha da artacağı değerlendirilmektedir.
													<br />
													Avrupa Birliği Üyeleri
													<br />
													<br />
													<table>
														<tr>
															<th>Almanya</th>
															<th>Avusturya</th>
															<th>Belçika</th>
															<th>Bulgaristan</th>
															<th>Çek Cumhuriyeti</th>
														</tr>
														<tr>
															<td>Danimarka</td>
															<td>Estonya</td>
															<td>Finlandiya</td>
															<td>Fransa</td>
															<td>İsveç</td>

														</tr>
														<tr>
															<td>Hollanda</td>
															<td>Letonya</td>
															<td>İrlanda</td>
															<td>İspanya</td>
															<td>Hırvatistan</td>

														</tr>
														<tr>
															<td>İtalya</td>
															<td>Polonya</td>
															<td>Litvanya</td>
															<td>Lüksemburg</td>
															<td>Macaristan</td>

														</tr>
														<tr>
															<td>Malta</td>
															<td>Portekiz</td>
															<td>Romanya</td>
															<td>Slovakya</td>
															<td>Slovenya</td>
															<td>Yunanistan</td>

														</tr>
													</table>
													<br />
													<br />

													Kaynak: Ticaret Bakanlığı

												</p>

											</div>
											<div className="ttr-post-footer">

												<div className="share-post ms-auto">
													<ul className="social-media">
														<li><strong>SHARE:</strong></li>
														<li><a target="_blank" href="https://www.facebook.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
														<li><a target="_blank" href="https://www.google.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-google-plus"></i></a></li>
														<li><a target="_blank" href="https://www.linkedin.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
														<li><a target="_blank" href="https://twitter.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-twitter"></i></a></li>
													</ul>
												</div>
											</div>
										</div>

									</div>




									<div className="blog-lg blog-single">
										<div className="action-box blog-lg">
											<img src={defaultPic6} alt="" />
										</div>
										<div className="info-bx">
											<ul className="post-meta">
												<li className="author"><img src={testPic1} alt="" />By <Link to="#">Irem</Link></li>
												<li className="date"><Link to="#">17 Ocak 2022</Link></li>
											</ul>
											<div className="ttr-post-title">
												<h3 className="post-title">KOSGEB YURT DIŞI PAZAR DESTEK PROGRAMI</h3>
											</div>
											<div className="ttr-post-text">
												<p>Programın temelde 4 ana amacı var. Bunlar şöyle sıralanabilir.
													<ol>
														<li>
															KOBİ’lerin yurt dışı pazar paylarını artırmak,
														</li>
														<li>
															KOBİ’leri uluslararası rekabetin aktörleri haline getirmek,
														</li>
														<li>
															İhracata başlayan KOBİ sayısını artırmak,
														</li>
														<li>
															E-ticarete başlayan KOBİ sayısını artırmak.
														</li>
													</ol>
												</p>
												<h4>Desteklenecek Giderler</h4>
												<ul>
													<li>
														Hizmet Alımı Giderleri
													</li>
													<li>
														Yurt Dışı Fuar ve Seyahat Giderleri

													</li>
													<li>
														Yazılım ve Donanım Giderleri
													</li>
													<li>
														Personel Gideri

													</li>
													<li>
														Test, Analiz ve Belgelendirme Giderleri
													</li>
													<li>
														Tanıtım Giderleri
													</li>
												</ul>

											
											<h4>
												Destek Oranları

											</h4>
											<p>

												%70 oranında geri ödemesiz ve
												<br />

												%30 oranında geri ödemeli destek sağlanır.

											</p>
											<h4>Başvuru Yapabilecek Kurum ve Kuruluşlar</h4>
											<p>
												İşletmenin programdan yararlanabilmesi için; Türk Ticaret Kanununda tanımlı gerçek veya tüzel kişi statüsünde olması ve KBS’de kayıtlı ve aktif durumda olması gerekir.
											</p>
											<h4>
												Proje Süresi
											</h4>
											<p>
												Proje süresi en az 8, en fazla 24 aydır. İşletme tarafından revizyon talep edilmesi halinde, proje süresi 24 ayı aşmamak kaydıyla kurul kararı ile 8 aya kadar ek süre verilebilir.
											</p>
											<h4>
												Dikkat Edilecek Hususlar
											</h4>
											<p>
												İşletmeler bu destekten sadece 1 kez yararlanabilir.
												<br />
												Programdan yararlanmak isteyen işletmelerin, başvuru tarihi itibariyle son onaylı mali yılda bilanço esasına göre defter tutmuş olması gerekir.
												<br />
												İşletmenin başvuru tarihi itibarı ile Yurt İçi Marka Tescil Belgesi’ne sahip olması gerekir.
												<br />
												İşletmenin proje bütçesi, son onaylı mali yıl net satış hasılatını aşmaması gerekir.
												<br />
												İşletmenin son onaylı mali yılda ihracat yapmış olması gerekir. (Ancak imalat sektöründe veya Öncelikli Teknoloji Alanları Tablosunda yer alan sektörlerde faaliyet gösteren işletmelerde bu şart aranmaz.)
												<br />
												Proje başvurusu en az 2 farklı gider grubundan oluşmalıdır.
												<br />
												Personel giderleri için destek oranı dikkate alınmaksızın; asgari geçim indirimi dâhil net asgari ücret tutarı temel girdi olmak üzere bu uygulama esaslarında belirlenen hesap yöntemi ve limitlere göre belirlenen tutar kadar geri ödemesiz destek sağlanır.
												<br />
												Projeye konu satın alınacak yazılımın yerli malı belgesi ile tevsik edilmesi durumunda geri ödemesiz destek oranına %15 ilave edilir ve geri ödemeli destek oranı aynı oranda azaltılır.
												<br />
												Proje kapsamında açık kaynak kodlu yazılım kullanılması durumunda geri ödemesiz destek oranına %15 ilave edilir.
												<br />
												Destek oranlarına ilave edilecek destek oranı %15’i, işletmeye ödenen destek tutarı ise destek üst limitlerini ve toplam destek üst limitini geçemez.
											</p>

										</div>

										<div className="ttr-post-footer">

											<div className="share-post ms-auto">
												<ul className="social-media">
													<li><strong>SHARE:</strong></li>
													<li><a target="_blank" href="https://www.facebook.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
													<li><a target="_blank" href="https://www.google.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-google-plus"></i></a></li>
													<li><a target="_blank" href="https://www.linkedin.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
													<li><a target="_blank" href="https://twitter.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-twitter"></i></a></li>
												</ul>
											</div>
										</div>
									</div>

								</div>














							</div>

						</div>
				</div>
			</section>

				</div >

			<Footer />

			</>
		);
	}
}

export default BlogDetails;