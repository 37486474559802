import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr3.jpg";

class Faq extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bnrImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>NEDEN İHRACAT YAPMALIYIZ</span></h1>
								
							</div>
						</div>
					</div>

					<section className="section-area section-sp3">
						<div className="container">
							<div className="row">
								<div className="col-lg-6">
									<Accordion defaultActiveKey="0" className="accordion ttr-accordion style1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												İHRACAT NEDİR?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">-Bir ülke içerisinde serbest dolaşımda olan bir ürünün veya hizmetin, ulusal sınırlar dışındaki alıcılara satılmasıdır.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												İHRACAT AŞAMALARI NELERDİR?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">-İhracat yapmayı istemek  - İhracatçılar Birliğine kayıt olmak  -İthalatçı bulmak  -İthalatçı firma ile sözleşme yapılması  - Proforma faturanın ihracatçı ve ithalatçı firma tarafından onaylanması    -Anlaşılan ödeme şekline göre ödemenin onaylanması  -Anlaşılan koşullarda ürünün hazırlanması
													-İhracat belgelerinin hazırlanması  -Anlaşılan teslim şekline göre ürünün nakliye tesliminin yapılması
													-İhracatçı birliğinden onay alınması –Gümrük işlemlerinin yapılması - İhracat taahhütlerinin kapatılması.
												</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												NİÇİN İHRACAT YAPMALIYIZ ?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">-İç pazara olan bağımlılığımızı azaltmak için
													-Pazar dalgalanmasını dinginlemek için
													-Gelir ve kar artışı elde etmek için
													-Üretim fazlası ve stoklarımızı satmak için
													-İhracat teşviklerinden yararlanmak için
													-Firmamızı geliştirmek için
													İhracat yapmalıyız.
												</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												İHRACATIN AVANTAJLARI NELERDİR?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">-Karlılık artar, -Satışlar artar, -Markalar tanınır, - İstihdam artar, -İşi büyütmenin en iyi yoludur, -Mevsimsel satışlarda ki dalgalar dengelenir, -Pazar riski dağıtılır, -Üretim artırılarak maliyet avantajı sağlanır, -Devalüasyona karşı korur.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												İHRACATIN RİSKLERİ NELERDİR?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">-Rekabet şartları olumsuz olabilir, -Ödeme gecikebilir yada ödeme alınamayabilir, -Satışlar istenilen düzeyde olmayabilir, -Döviz kuru kaynaklı zararlar oluşabilir.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
								<div className="col-lg-6">
									<Accordion defaultActiveKey="2" className="accordion ttr-accordion style1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												İHRACAT AŞAMALARI NELERDİR?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">-	Fizibilite analizi
													-	Uluslararası pazarlara giriş stratejisinin oluşturulması
													-	Uygulama
													.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												İHRACAT MÜŞTERİSİ BULMA YOLLARI NELERDİR?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">-Sade ve işlevsel web sitesi, -B2B Firma Rehberleri, -Sosyal Medya, -Dijital Pazarlama, - Ticaret Müşavirlikleri, -Fuar Katılımı, -Pazar Araştırma Raporları, -Sektörel Yayınlar, -Pazar Firma Ziyaretleri.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												SWOT ANALİZİ NEDEN GEREKLİDİR
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">SFirmanın ihracat performans belirlemesinde ve eylem planı oluşturulurken içsel ve dışsal çevreye uygun ihracat pazarlama stratejisi  belirlemek için sistemli bir sürecin izlenmesi çok önemlidir..</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												SWOT
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">-Fırsatlar, - Tehditler, - Güçlü Yönler, -Zayıf Yönler.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												YEŞİL MUTABAKAT EYLEM PLANI 2022
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Sürdürülebilir ve kapsayıcı bir küresel ekonominin inşası
													• Ekonomik büyümeyi iklim gündemini göz önünde tutarak gerçekleştirmek
													• Yatırımcılar ile işletmelerin küresel isınmayı sınırlandıran bir senaryo ile uyumlu faaliyet göstermelerini teşvik etmek
													• İklim değişikliği ile mücadelenin uluslararası ekonomi ve ticaret politikalarının merkezi haline gelmesi
													• Ülkemiz kalkınma hedefleriyle uyumlu bir şekilde sürdürülebilir, kaynak-etkin ve yeşil bir ekonomiye geçişi destekleyecek dönüşümün sağlanması
													• Türkiye ekonomisi ve sanayisinin yeşil dönüşümü Ülkemizin AB başta olmak üzere, üçüncü ülkelere ihracatında rekabetçiliğinin korunması ve güçlendirilmesi
													• Ülkemizin küresel değer zincirlerine entegrasyonunun geliştirilmesi ve Ülkemizin uluslararası yatırımlardan alacağı payın artırılması
													• Yeşil ve ekonomik bütçe düzenlemeleri
													• Yeşil ve temiz ekonomik finansman
													• Sürdürülebilir tarım • sürdürülebilir eğitim
													• İklim değişikliği ile kampanya Diplomasi
													• Avrupa Yeşil Mutabakatı bilgilendirme ve bilinçlendirme faaliyetleri
													• Sürdürülebilir tarım • sürdürülebilir eğitim • Yeşil ve temiz ekonomik finansman
													• Yeşil ve temiz ekonomik finansman • Yeşil ve temiz ekonomik finansman
													• Sürdürülebilir tarım
													• İklim değişikliği ile tartışma
													• Avrupa Yeşil Mutabakatı bilgilendirme ve bilinçlendirme faaliyetleri
													.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							</div>
						</div>
					</section>

					

				</div>

				<Footer />

			</>
		);
	}
}

export default Faq;