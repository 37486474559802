import React, {Component} from 'react';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr4.jpg";



class Booking extends Component{
	
	


	render(){


		function SendEmail(e) {
	
			e.preventDefault();
		
			emailjs.sendForm('service_8610ocg', 'template_1mq0n3r', e.target, 'user_pBufZjaSBnQNMvV8HJ96z')
			  .then((result) => {
				  console.log(result.text);
			  }, (error) => {
				  console.log(error.text);
			  });
			  e.target.reset()
		
		}
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>GÖRÜŞME TALEBİ</span></h1>
								
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-7 mb-30 mb-md-50">
									<form className="booking-form" method="post" action="khansahib310@gmail.com" onSubmit={SendEmail}>
										<div className="row">
											
											<div className="col-lg-12">
												<div className="form-group">
													<h6 className="form-title">FİRMA BİLGİLERİ</h6>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="Firma_ismi" type="text" className="form-control" placeholder="FİRMA  İSMİ" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="Email" type="email" placeholder="EMAİL" className="form-control" required/>
													</div>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group">
													<div className="input-group">
														<input name="Telefon_Numarasi" type="text" placeholder="TELEFON NUMARASI" className="form-control" required/>
													</div>
												</div>
											</div>
											
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
														<textarea name="Mesaj" rows="4" className="form-control" required=""  placeholder="Mesajınızı Burada Yazabılırsınız"></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<button name="submit" type="submit" value="Send Email" className="btn btn-primary btn-lg"> MESAJ GÖNDERİN</button>
											</div>
										</div>
									</form>
								</div>
								
							</div>
						</div>
					</section>
					
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default Booking;