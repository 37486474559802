import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr99.jpg";
import team3 from "../../images/team/team3.jpg";

class TeamDetails extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bnrImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>PARTNER FIRMALAR</span></h1>

							</div>
						</div>
					</div>

					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row member-detail">

								<div className="col-lg-12 col-md-7 mb-30">
									<h3 className="title">BMT EXPORT</h3>
									<p className="mb-30">BMT dış ticaret Ortadoğu ve Asya pazarında ihracat faliyetlerini sürdürmekte olan bir ortak girişimdir. Müşteri gereksinimlerini anlayıp buna göre proaktif ve tam zamanlı çözümler üretmek hizmet anlayışımızın temellerini oluşturmaktadır. Amacımız doğru piyasa araştırması yaparak fiyatlandırma yapıları, ürün çeşitlilikleri, satın alma süreçlerindeki karmaşıklıklar giderilip doğru malın doğru fiyat ve terminde müşteriyle buluşmasını sağlamaktır.
										Faaliyette bulunduğumuz sektörler:
										o	İnşaat, yapı malzemeleri
										o	Enerji ve Elektrik kabloları
										o	Mobilya ve Kapı
										o	Yangın sistemleri
										o	Aydınlatma Sistemleri
										o	ısıtma soğutma , havalandırma sistemleri
										o	Kazan Sistemleri
										o	Trafo , Yüksek Gerilim Hatları
										o	Pompa ve hidrofor, sulama sistemleri

										<br></br>

										<a href="https://www.bmtexport.com/neler-yapiyoruz">BMT EXPORT Firmanın siteyi ziyaret edebilmek için burda tiklayın</a>


									</p>
									<h3 className="title">EIR MEDICAL</h3>
									<p className="mb-30">EIRMEDICAL
										Yurtdışında Bükreş, Romanya'da bulunan eksiksiz bir tedavi hizmetleri ve karşılaştığınız soruna özgü tedavi seçenekleri sunan, alandaki en seçici ve en kaliteli şirketlerden biridir.
										Türkiye, Almanya, İsrail ve ABD'deki en iyi ve en nitelikli doktorları seçerek işe alıyor ve işbirliği yapıyoruz.
										Karşılaştığınız sorun için, hastalığınızı tedavi etme konusunda deneyimli doktorlardan en uygun tedavi önerilerine aracılık ediyoruz ve talep edilen alanda dünya çapında tıbbi yardım sunuyoruz.
										Hastaların tıbbi belgelerine dayanarak, tanıyı tamamlamak, tedaviyi onaylamak ve ikinci bir tıbbi görüş almak için ücretsiz danışmanlık hizmeti sunuyoruz.
										Yurtdışı tedavi hizmetlerimiz arasında tıbbi görüşlerin alınması, tıbbi tedavi ile ilgili maliyetlerin alınması, kliniğe ulaşımın düzenlenmesi, tıbbi ziyaretin düzenlenmesi ve kişiselleştirilmiş tedavi sonrası izlemeye aracılık etme yer almaktadır.
										EIR MEDICAL 2008 yılında, karşılaştığınız sağlık konusunda hastaları doğru doktorlara yönlendirmek için doğmuştur.
										EIR MEDICAL, hastalarımıza en eksiksiz ve kapsamlı tıbbi tedavileri sunmak için yurtdışından en iyi uzman doktorları seçerek çözüm aramak amacıyla oluşturuldu.
										Ekibimiz, tıbbi ziyaretinizi planlama ve organize etme konusunda size her adımda rehberlik edmektedir.
										FAALİYETLERİMİZ
										Sağlık Turizmi,
										Sağlık Turizm Danışmanlığı ve
										Yurtdışında hastane, sağlık kuruluşu tanıtım ve reklam çalışmaları.
										<br></br>
										
										<a href="http://eirmedical.ro/ro/servicii/eir-medical-consulting/">EIR MEDICAL Firmanın siteyi ziyaret edebilmek için burda tiklayın</a>
										</p>


								</div>
							</div>
						</div>
					</section>

				</div>

				<Footer />

			</>
		);
	}
}

export default TeamDetails;