import React, { Component } from 'react';

// Images
import car1 from "../../images/why-choose/car-1.png";
import car2 from "../../images/why-choose/car-2.png";
import car3 from "../../images/why-choose/car-3.png";
import car4 from "../../images/why-choose/car-4.png";
import car5 from "../../images/why-choose/car-5.png";
import car6 from "../../images/why-choose/car-6.png";
import car7 from "../../images/why-choose/car-7.png";
import car8 from "../../images/why-choose/car-8.png";
import car9 from "../../images/why-choose/car-9.png";
import car10 from "../../images/why-choose/car-10.png";
import car11 from "../../images/why-choose/car-11.png";
import car12 from "../../images/why-choose/car-12.png";
import car15 from "../../images/why-choose/car-15.png";
import car16 from "../../images/why-choose/car-16.png";
import car17 from "../../images/why-choose/car-17.png";
import car18 from "../../images/why-choose/car-18.png";
import car19 from "../../images/why-choose/car-19.png";

class PartnerShipsSection extends Component{
	render(){
		return(
			<>
				<section >
					<div className="container">
						<div className="row align-items-center">
							<div className="col-xl-5 col-lg-5">
								<div className="heading-bx mb-4 pr-lg-5">
									<h6 className="title-ext m-b0 text-primary">KURUMLAR / PARTNERLER</h6>
									<h2 className="title">TEKNOLOJI PARTNERLERIMIZ</h2>
									
								</div>
							</div>
							<div className="col-xl-7 col-lg-7 mb-30">
								<ul className="brand-list">


									<li><img src={car15} alt=""/></li>
									<li><img src={car16} alt=""/></li>
									<li><img src={car17} alt=""/></li>
									<li><img src={car18} alt=""/></li>
									<li><img src={car19} alt=""/></li>


									
									<li><img src={car1} alt=""/></li>
									<li><img src={car2} alt=""/></li>
									<li><img src={car3} alt=""/></li>
									<li><img src={car4} alt=""/></li>
									<li><img src={car5} alt=""/></li>
									<li><img src={car6} alt=""/></li>
									<li><img src={car7} alt=""/></li>
									<li><img src={car8} alt=""/></li>
									<li><img src={car9} alt=""/></li>
									<li><img src={car10} alt=""/></li>
									<li><img src={car11} alt=""/></li>
									<li><img src={car12} alt=""/></li>
									
									
								</ul>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default PartnerShipsSection;