import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Import Images
import bg1 from "../../images/background/bg1.jpg"


// Team Content
const content = [
	{ 
	
		name: "Eclipse Plastik San ve Tic. Ltd. Şti",
		text: "İhracatımızı artırmak için, spaceecs.com internet sitesi aracılığıyla firma yetkililerine ulaşıp destek aldık. İhracat planlama ve yol haritamız konusunda bize son derece destek olan spaceECS ekibine çok teşekkür ederiz.",		
	},
	{ 
		
		name: "Nalbant Metal San. Tic. Ltd. Şti",
		text: "İhracat yapmak için hangi pazardan daha fazla verim alıp daha hızlı ilerleyebiliriz planlama aşamasındayken karşımıza SpaceECS firması çıktı, kendileriyle firmamız için ihracatta en etkili pazarları belirledik ve yeni müşterilerimizle görüşmeye başladık. SpaceECS firmasına çok teşekkür eder, çalışmanızı tavsiye ederiz.",		
	},
	{ 
		
		name: "Global Med San. Tic. Ltd. Şti",
		text: "Romanya, Almanya ve Hollanda pazarlarında yeni müşteriler bulmamızı sağlayan SpaceECS firmasına Çok teşekkür ederiz. İhracat Markalaşma sürecimizde de firmadan destek almaya devam edeceğiz. Sizlerde firmayla gönül rahatlığıyla çalışabilirsiniz.",		
	},
	{ 
		
		name: "Powerlink Elektrik San. Ve Dış Tic. Ltd. Şti.",
		text: " Ticaret Bakanlığının bize sunmuş olduğu birçok teşvik ve destek varmış ve biz firma olarak görünürlüğümüzü artırma konusunda yeterli bilgiye sahip değilmişiz.  SpaceECS firması yetkilileri ile yaptığımız görüşmeler neticesinde tanıtım çalışmalarımızı yapmış olduğumuz , firmamıza fayda sağlayacak bir çok desteğe başvurumuzu  yaptık. Kendilerine bize bu konuda destek olup başvurularımızı yaptıkları için teşekkür ederiz.",		
	},
]

class TestimonialSection extends Component{
	render(){
		
		const settings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 4,
			slidesToScroll: 3,
			slidesToShow: 2,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 2000,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 1600,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1191,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				
				<section className="section-area section-sp2 bg-gray ovbl-dark testimonial-area" style={{backgroundImage: "url("+bg1+")", backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "cover"}}>
					<div className="container">
						<div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
							<div className="clearfix mb-2">
								
								<h2 className="title mb-0">Referanslarımız</h2>
							</div>
							
						</div>
						<Slider {...settings} className="testimonial-carousel-1 slick-arrow-none">
							{content.map((item) =>(
								<div className="slider-item">								
									<div className="testimonial-bx">
										<div className="testimonial-content">
											<p>{item.text}</p>
										</div>
										<div className="client-info">
											<div className="testimonial-info">
												<h6 className="name">- {item.name}</h6>
											</div>
											
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</section>
				
			</>
		);
	}
}

export default TestimonialSection;