import React, { Component } from 'react';
import Slider from "react-slick";

// Import Images
import slide1 from "../../../images/slider/slide1.jpg"
import slide3 from "../../../images/slider/slide3.jpg"


class SliderOne extends Component{
	render(){
		
		return(
			<>
				
				<div className="ttr-slider style-2">
					<Slider className="ttr-swiper01">
						<div className="slider-item">								
							<div className="slider-img slide-move" style={{backgroundImage: "url("+ slide1 +")"}}>
								<img src={slide1} alt=""/>
							</div>
							<div className="slider-content container align-items-center text-center text-white">
								
									
									<h2 className="title">İHRACATTA B2B MARKETING</h2>
									
								
							</div>
						</div>
						<div className="slider-item">								
							<div className="slider-img slide-move" style={{backgroundImage: "url("+ slide3 +")"}}>
								<img src={slide3} alt=""/>
							</div>
							<div className="slider-content container align-items-center text-center text-white ">
								
								
								<h2 className="title text-white " ><a href="https://madeintur.com/" className="text-white">Madeintur.com</a></h2>
									
								
							</div>
						</div>
					</Slider>
				</div>
				
			</>
		);
	}
}

export default SliderOne;