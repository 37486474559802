import React,{Component,useState, useEffect} from 'react'; 
import {Link} from 'react-router-dom';
import Masonry from 'react-masonry-component';
import SimpleReactLightbox, {SRLWrapper, useLightbox} from 'simple-react-lightbox';

// Images
import Pic1 from "../../../images/services/pic1.jpg"
import Pic2 from "../../../images/services/pic2.jpg"
import Pic3 from "../../../images/services/pic3.jpg"
import Pic4 from "../../../images/services/pic4.jpg"
import Pic5 from "../../../images/services/pic5.jpg"
import Pic6 from "../../../images/services/pic6.jpg"
import Pic7 from "../../../images/services/pic7.jpg"
import Pic8 from "../../../images/services/pic8.jpg"
import Pic9 from "../../../images/services/pic9.jpg"
import Pic10 from "../../../images/services/pic10.jpg"
import Pic11 from "../../../images/services/pic11.jpg"
import Pic12 from "../../../images/services/pic12.jpg"
// Portfolio Content
const content = [
	{ 
		thumb: Pic1, 
		LightImg: Pic1,
		tag: ['Oil & Gas',],
		title: "OIL CHANGE",
	},
	{ 
		thumb: Pic3,
		LightImg: Pic3,
		tag: ['Oil & Gas',],
		title: "BREAK REPAIR",
	},
	{ 
		thumb: Pic4,
		LightImg: Pic4,
		tag: ['Industrial',],
		title: "CAR WHEELS",
	},
	{ 
		thumb: Pic2,
		LightImg: Pic2,
		tag: ['Chemical',],
		title: "GENERAL SERVICE",
	},
	{ 
		thumb: Pic5,
		LightImg: Pic5,
		tag: ['development',],
		title: "OIL CHANGE",
	},
	{ 
		thumb: Pic6,
		LightImg: Pic6,		
		tag: ['Metallurgy',],
		title: "BREAK REPAIR",
	},
	{ 
		thumb: Pic7,
		LightImg: Pic7,		
		tag: ['Industrial',],
		title: "CAR WHEELS",
	},
	{ 
		thumb: Pic8,
		LightImg: Pic8,
		tag: ['Metallurgy',],
		title: "GENERAL SERVICE",
	},
	{ 
		thumb: Pic9,
		LightImg: Pic9,
		tag: ['Metallurgy',],
		title: "GENERAL SERVICE",
	},
	{ 
		thumb: Pic10,
		LightImg: Pic10,
		tag: ['Metallurgy',],
		title: "GENERAL SERVICE",
	},
	{ 
		thumb: Pic11,
		LightImg: Pic11,
		tag: ['Metallurgy',],
		title: "GENERAL SERVICE",
	},
	{ 
		thumb: Pic12,
		LightImg: Pic12,
		tag: ['Metallurgy',],
		title: "GENERAL SERVICE",
	},
]

// Magnific Anchor
const MagnificAnchor = props => {
	const { openLightbox } = useLightbox()

	return (
		<Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="magnific-anchor ttr-icon" >
			<i className="la la-plus"></i>
		</Link>
	)
}

const options = {
	settings: {
		overlayColor: "rgba(0,0,0,0.9)",
		backgroundColor: "#fe5a0e",
		slideAnimationType: 'slide',
	},
	buttons: {
		backgroundColor: "#fe5a0e",
		iconColor: "rgba(255, 255, 255, 1)",
		showAutoplayButton: false,
		showDownloadButton: false,
	},
	caption: {
		captionColor: "#a6cfa5",
		captionFontFamily: "Raleway, sans-serif",
		captionFontWeight: "300",
		captionTextTransform: "uppercase",
	}
};

const FilterList = ({dataFilter, defaultTag, activeFilter}) => {                                                               
	return (	
		<li className={`${activeFilter ? 'btn active' : 'btn'}` } onClick={() => defaultTag(dataFilter)} >
			<Link to={"#"}><span>{dataFilter}</span></Link>
		</li> 
	);
};

function GalleryContent(){
	const [tag, setTag] = useState('All Cases');
	const [filteredImages, setFilterdImages] = useState([]);
	
	useEffect( () => {
		tag === 'All Cases' ? setFilterdImages(content) : setFilterdImages(content.filter( image => image.tag.find(element => element === tag)))
	}, [tag])	
	
	return(
			
			<>
			
			
			
			<SimpleReactLightbox>
				<SRLWrapper options={options}>
					<Masonry className="row sp5 mb-0">
						{filteredImages.map((item, index)=>(	
							<div className="action-card col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
								<div className="portfolio-box style-1 mb-2">
									<div className="media">
										<img src={item.thumb} alt=""/>
										
									</div>
									
								</div>
							</div>
						))}	
					</Masonry>
				</SRLWrapper>
			</SimpleReactLightbox>
		</>
		
	);
}

class GalleryMasonry extends Component{
	render(){
		return(
			<>
				<GalleryContent />
			</>
		)
	}
}
export default GalleryMasonry;