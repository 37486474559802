import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr99.jpg";
import team3 from "../../images/team/team3.jpg";

class TeamDetails extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bnrImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>YATIRIMLARIMIZ</span></h1>

							</div>
						</div>
					</div>

					<section className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row member-detail">

								<div className="col-lg-12 col-md-7 mb-30">
									<h3 className="title">REMMYHOME</h3>
									<p className="mb-30">REMMYHOME 2012 tarihinde İstanbul merkezli olarak kurulmuş mutfak ürünleri ihracatı yapan ihracat tabanlı bir firmadır.Başta müşteri memnuniyeti olmak üzere tüketicilerimizin var olan veya olabilecek ihtiyaçlarını doğru tespit edip karşılanmasına odaklanarak yeni
										konsept ve tasarımlarla müşteri memnuniyeti sağlar. Sürekli iyileştirme yaklaşımı ile verimliliği uluslararası düzeyde rekabet edebilecek seviyeye yükseltir. Değişen yenilik ve teknolojilere ayak uydurarak kendini sürekli geliştirir. Günden güne büyüyen şirketimiz değerlerinden
										vazgeçmeyip toplumsal faydaya önem vererek hizmetlerine devam edecektir.
										Başta Rusya ve Avrupa Ülkeleri olmak üzere kendi RemmyHome markasıyla ürettirdiği ürünler hariç müşterilerinden gelen taleplere göre farklı üretici firmalardan temin yaparak firmalarında ihracatlarına katma değer sağlamaktadır.
										RemmyHome ihracat ürünlerimiz ; Kavanozlar Saklama Kapları, Limonluk,  Şişeler, Sürahiler Tuzluklar , Yağlıklar&Sirkelikler, Baharatlıklar,  Çok Amaçlı Ürünler,  Desenli Seriler, Lisanslı Ürünler, Plastik Ürünler, Remmy Bambu, Remmy Plus Mug -ThermoMuglar,  Renkli Mutfaklar Ekipmanlarıdır.

										Güçlü organizasyon yapısı, dinamik ve uzman kadrosu, ileri teknolojisi ile dünya çapında tanınan bir marka olan REMMYHOME, çalışanına değer veren kültürüyle iyi bir geleceğe inanmaktadır. Firmamız; sahip olduğu misyon ve vizyon doğrultusunda bizimle yol almaya karar veren çalışanlarıyla birlikte gelişerek büyümeye devam edecektir.






										.</p>

								</div>
							</div>
						</div>
					</section>

				</div>

				<Footer />

			</>
		);
	}
}

export default TeamDetails;