import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import WidgetBrochure from "../elements/widget/widget-brochure";
import WidgetHelp from "../elements/widget/widget-help";

// Images
import bnrImg from "../../images/banner/bnr5.jpg";
import Pic4 from "../../images/service-section/pic4.jpg";

class ServiceEngineDiagnostics extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Yurtdışı Reklam ve Markalaşma</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									
								</nav>
							</div>
						</div>
					</div>
					
					<div className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-5">
									<aside className="sticky-top pb-1">
										
										<div className="widget">
											<ul className="service-menu">
												<li className="active"><Link to="/service-engine-diagnostics"><span>Yurtdışı Reklam ve Markalaşma</span><i className="fa fa-angle-right"></i> </Link></li>
												<li><Link to="/service-lube-oil-and-filters"><span>Uluslararası İş Geliştirme ve İhracat Danışmanlıği</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-belts-and-hoses"><span>Yurtdışı Rakıp Analızı</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-air-conditioning"><span>Sağlık Turizmi Yurtdışı Pazarlama Danışmanlığı</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-brake-repair"><span>Yurt Dışı Pazar Araştırması</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-tire-and-wheel-services"><span>Stratejik Yönetim ve Planlama</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										
										
										<WidgetBrochure />										
										
									</aside>
								</div>
								<div className="col-lg-8 col-md-7">
									<div className="ttr-media mb-30">
										<img src={Pic4} className="imghight" alt=""/>
									</div>
									<div className="mb-30">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Yurt Dışı Reklam ve Markalaşma</h4>
											<p className="mb-0">İhtiyaçlara artan talepler neticesinde Küreselleşen dünya üreticiler için büyük bir pazaryeri haline gelmiştir. Global dünyada Ülkelerdeki başarılı markaların çokluğu o ülkelerin imaj ve saygınlığının teminatıdır. 

Tüketiciler için marka bir güvence üreticiler için stratejik bir güçtür. Bunların neticesinde günümüzde markalaşma çalışmaları hız kazanmış üreticiler Marka Gücünün önemini anlamıştır. Üreticiler markalarını yarattıktan sonra sadece göstermekle değil uzun vadeli stratejik planlarla güvence altına alıp sağlamlaştırmalıdır. Markalaşma sürecinde Space ECS olarak sizinle birlikte yol haritanızı belirliyor Global dünya trendleriyle aynı kulvarda olmanız için Yurtdışı Reklam çalışmalarınızda yanınızda oluyor uzun yıllara dayanan tecrübemizle en verimli ve en hızlı sonuç almanız için tüm sürecinizi organize ediyoruz. Markalaşmaya Büyük önem veren Ekonomi Bakanlığımız Yurtdışı Reklam ve Markalaşma harcamalarınızın bulunduğunuz sektöre göre % 50 - %60 ‘ ı oranında olmak suretiyle 200.000 USD  / 500.000 USD ye kadar tutarını teşvikler kapsamında destekleyerek sağlam ve sürdürüle bilir ihracat ağı oluşturmamızda yanımızda oluyor.</p>
										</div>
										
									</div>
									
								</div>
							</div>
						</div>
					</div>
				
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default ServiceEngineDiagnostics;