import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import team1 from "../../images/team/team1.jpg";
import team2 from "../../images/team/team2.jpg";
import team3 from "../../images/team/team3.jpg";
import team4 from "../../images/team/team4.jpg";

class TeamOneSection extends Component{
	render(){
		return(
			<>
				<section className="section-area section-sp1">
					<div className="container">
						<div className="heading-bx text-center">
							<h2 className="title mb-0">İHRACAT DESTEKLERİ</h2>
						</div>
						<div className="row">
							<div className="col-lg-3 col-sm-6">
								<div className="team-member style-1 mb-30">
									<div className="team-media">
										<img src={team1} alt=""/>
									</div>
									<div className="team-info">
										<h4 className="title"><Link to="/team-details">Markalaşma Destekleri</Link></h4>
										<ul className="icon-list">
											<p>Yurtdışında reklam ve tanıtım çalışmaları yaprak....</p>
										</ul>
										
											<Link to="/team-details" className="btn btn-primary">Bilgi için tiklayın</Link>
										
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6">
								<div className="team-member style-1 mb-30">
									<div className="team-media">
										<img src={team2} alt=""/>
									</div>
									<div className="team-info">
										<h4 className="title"><Link to="/team-details">Pazar Giriş Destekleri</Link></h4>
										<ul className="icon-list">
											<p>Ülkemizde üretici veya ticari bulunan....</p>
										</ul>
										
											<Link to="/team-details" className="btn btn-primary">Bilgi için tiklayın</Link>
										
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6">
								<div className="team-member style-1 mb-30">
									<div className="team-media">
										<img src={team3} alt=""/>
									</div>
									<div className="team-info">
										<h4 className="title"><Link to="/team-details">PAZAR ARAŞTIRMA DESTEKLERİ</Link></h4>
										<ul className="icon-list">
											<p>Hedef Pazarlara....</p>
										</ul>
										
											<Link to="/team-details" className="btn btn-primary">Bilgi için tiklayın</Link>
										
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6">
								<div className="team-member style-1 mb-30">
									<div className="team-media">
										<img src={team4} alt=""/>
									</div>
									<div className="team-info">
										<h4 className="title">TİCARET BAKANLIĞI IHRACAT DESTEKLERİ</h4>
										<ul className="icon-list">
											<p></p>
										</ul>

										<a href="https://ticaret.gov.tr/destekler/ihracat-destekleri" className="btn btn-primary"  role="button">Bilgi için tiklayın</a>
			
											
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default TeamOneSection;