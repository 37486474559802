import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import WidgetBrochure from "../elements/widget/widget-brochure";
import WidgetHelp from "../elements/widget/widget-help";

// Images
import bnrImg from "../../images/banner/bnr5.jpg";
import Pic6 from "../../images/service-section/pic6.jpg";

class ServiceEngineDiagnostics extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Stratejik Yönetim ve Planlama</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									
								</nav>
							</div>
						</div>
					</div>
					
					<div className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-5">
									<aside className="sticky-top pb-1">
										
										<div className="widget">
											<ul className="service-menu">
												<li className="active"><Link to="/service-engine-diagnostics"><span>Yurtdışı Reklam ve Markalaşma</span><i className="fa fa-angle-right"></i> </Link></li>
												<li><Link to="/service-lube-oil-and-filters"><span>Uluslararası İş Geliştirme ve İhracat Danışmanlıği</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-belts-and-hoses"><span>Yurtdışı Rakıp Analızı</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-air-conditioning"><span>Sağlık Turizmi Yurtdışı Pazarlama Danışmanlığı</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-brake-repair"><span>Yurt Dışı Pazar Araştırması</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/service-tire-and-wheel-services"><span>Stratejik Yönetim ve Planlama</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										
										<WidgetBrochure />

										
										
										
									</aside>
								</div>
								<div className="col-lg-8 col-md-7">
									<div className="ttr-media mb-30">
										<img src={Pic6} className="imghight" alt=""/>
									</div>
									<div className="mb-30">
										<div className="head-text mb-30">
											<h4 className="title mb-10">Stratejik Yönetim ve Planlama</h4>
											<p className="mb-0">
İhracat hedeflerimizin, Neresindeyiz ve Neresin de olmak istiyoruz? Olmak istediğimiz yere en hızlı, en inovatif ve en doğru şekilde nasıl ulaşırız.. Space ECS Group durum analizinden başlayarak gerekli stratejik istihbarat çalışmaları ile size en uygun yöntemlerle sonuçları planlar. Hedefinize en uygun yapıyı oluşturarak en hızlı ve doğru yöntemlerle olmak istediğiniz yerde olmanızda en büyük çözüm ortağınız olur.</p>
										</div>
										
									</div>
									
								</div>
							</div>
						</div>
					</div>
				
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default ServiceEngineDiagnostics;