import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import WidgetContact from "../elements/widget/widget-contact";

// Images
import bnrImg from "../../images/banner/bnr99.jpg";

class JobCareer extends Component {

	render() {
		return (
			<>

				<Header />

				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bnrImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>KARİYER</span></h1>
								
							</div>
						</div>
					</div>

					<div className="section-area bg-gray section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-7 mb-md-20">
									<div className="heading-bx">
										<h6 className="title-ext text-primary">Kariyer</h6>
										<h2 className="title mb-0">BİZİMLE ÇALIŞMAK İSTERMİSİNİZ? </h2>
										
									</div>
									<div className="row">	
										
										<div className="col-md-12 mb-30">
											<div className="job-career-box">
											<form className="booking-form">
													<div className="row">


														<div className="col-lg-12">
															<div className="form-group">
																<h6 className="form-title">kİŞİSEL BİLGİLER</h6>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group">
																	<input name="name" type="text" className="form-control" placeholder="İsim" required />
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group">
																	<input name="phone" type="email" placeholder="Email" className="form-control" required />
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group">
																	<input name="phone" type="text" placeholder="Telefon Numara" className="form-control" required />
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group">
																	<input name="subject" type="text" required="" className="form-control" placeholder="Konu" />
																</div>
															</div>
														</div>
														
														<div className="col-lg-12">
															<button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg"> Mesaj Gönderin</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								
							</div>

						</div>
					</div>

				</div>

				<Footer />

			</>
		);
	}
}

export default JobCareer;