import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import WidgetBrochure from "../elements/widget/widget-brochure";

class FeatureTwoSection extends Component {
	render() {
		return (
			<>
				<section className="section-area section-sp1">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2">
									
									<div className="icon-content">
										<h5 className="ttr-tilte">YALOVA İLİ İHRACAT PAZAR ARAŞTIRMA RAPORU</h5>
										<p>Yalova ili ihracat Pazar Araştırma Raporu burdan indirebilirsiniz.</p>
										<div class="columns download">
											<p>
											<a href="/yalova.pdf" download>iNDİRİN</a>

											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2 bg-secondary">
									
									<div className="icon-content">
										<h5 className="ttr-tilte">BİRLİKTE GÜÇLÜYÜZ</h5>
										<p>Dergimiz buradan indirebilirsiniz.</p>
										<div class="columns download">
											<p>
											<a href="/dergi.pdf" download>iNDİRİN</a>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2">
									
									<div className="icon-content">
										<h5 className="ttr-tilte">SPACE GROUP EXPORT CONSULTANCY SERVICES</h5>
										<p>Space Group Export Consultancy Services hakkında dosya burdan indirebilirsiniz. </p>
										<div class="columns download">
											<p>
											<a href="/space.pdf" download>iNDİRİN</a>

											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2 bg-warning">
									<div className="icon-content">
										<h5 className="ttr-tilte">NORVEÇ ÜLKE GÜNCEL RAPORU 2021</h5>
										<p>Nörveç Ülke Bilgi notu  buradan indirebilirsiniz.</p>
										<div class="columns download">
											<p>
											<a href="/NORVEÇ.pdf" download>iNDİRİN</a>
											</p>
										</div>

									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2 bg-primary">
									
									<div className="icon-content">
										<h5 className="ttr-tilte">FRANSA ÜLKE GÜNCEL RAPORU 2021</h5>
										<p>Fransa Ülke Bilgi notu  buradan indirebilirsiniz.</p>
										<div class="columns download">
											<p>
											<a href="/FRANSA.pdf" download>iNDİRİN</a>
											</p>
										</div>
									</div>
								</div>
							</div>
							
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2">
									
									<div className="icon-content">
										<h5 className="ttr-tilte">ABD ÜLKE GÜNCEL RAPORU 2021</h5>
										<p>ABD Ülke Bilgi notu burdan indirebilirsiniz.</p>
										<div class="columns download">
											<p>
											<a href="/ABD.pdf" download>iNDİRİN</a>

											</p>
										</div>
									</div>
								</div>
							</div>
							
						
							
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default FeatureTwoSection;