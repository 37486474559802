import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Import Images
import testPic1 from "../../images/testimonials/pic1.jpg"
import testPic2 from "../../images/testimonials/pic2.jpg"
import testPic3 from "../../images/testimonials/pic3.jpg"

import blogGridPic1 from "../../images/blog/grid/pic1.jpg"
import blogGridPic2 from "../../images/blog/grid/pic2.jpg"
import blogGridPic3 from "../../images/blog/grid/pic3.jpg"


// Team Content
const content = [
	{ 
		postMedia: blogGridPic1,
		postAuthor: testPic1,
		postAuthorName: "Irem",
		postDate: "17 Ocak 2022",
		postTitle: "Yanı Başımızdaki Dev Pazar Avrupa Birliği",		
		postText: "27 ülkeden oluşan Avrupa Birliği (AB), dünya üzerindeki en büyük siyasi ve ekonomik örgütlenmedir.",
	},
	{ 
		postMedia: blogGridPic2,
		postAuthor: testPic2,
		postAuthorName: "Irem",
		postDate: "17 Ocak 2022",
		postTitle: "KOSGEB YURT DIŞI PAZAR DESTEK PROGRAMI",		
		postText: "Programın temelde 4 ana amacı var. KOBİ’lerin yurt dışı pazar paylarını artırmak, KOBİ’leri uluslararası rekabetin aktörleri haline getirmek, İhracata başlayan KOBİ sayısını artırmak, E-ticarete başlayan KOBİ sayısını artırmak.",	
	},
	{ 
		postMedia: blogGridPic3,
		postAuthor: testPic3,
		postAuthorName: "Irem",
		postDate: "17 Ocak 2022",
		postTitle: "Akıllı Müşteri Temelini Oluşturan Uygulamalar Neler?",		
		postText: "Kapatılma aşamasına gelmiş Türkiye’deki bir kargo şirketinin müşteri analizleri neticesinde değiştirdiği müşteri politikaları, müşteri yönetiminin bir şirkette neleri değiştirebildiğine iyi bir örnektir:",
	}
]

class LatestBlogSection extends Component{
	render(){
		
		const settings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1191,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				
				<section className="section-area section-sp2 bg-gray ov-hidden">
					<div className="container">
						<div className="heading-bx text-center">
							
							<h2 className="title mb-0">BLOG YAZILARIMIZ</h2>
							
						</div>
						<Slider {...settings} className="service-slide slick-arrow-none">
							{content.map((item) =>(
								<div className="slider-item">								
									<div className="blog-card style-1 bg-white shadow">
										<div className="post-media">
											<Link href="blog-details.html"><img src={item.postMedia} alt=""/></Link>
										</div>
										<div className="post-info">
											<h5 className="post-title"><Link to="/blog-details">{item.postTitle}</Link></h5>
											<div className="post-content">
												<p className="mb-0">{item.postText}</p>
											</div>
											<ul className="post-meta">
												<li className="author"><img src={item.postAuthor} alt=""/>By <Link to="/blog-details">{item.postAuthorName}</Link></li>
												<li className="date"><Link to="/blog-details">{item.postDate}</Link></li>
											</ul>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</section>
				
			</>
		);
	}
}

export default LatestBlogSection;